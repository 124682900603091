import React from "react";
import { Container } from "@material-ui/core";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import InvestmentROIComponent from "../WebSite/InvestmentROI/InvestmentROIComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import "./InvestmentsComponent.css";
import InvestmentSection2 from "./InvestmentSection2/InvestmentSection2";
import InvestmentSection4 from "./InvestmentSection4/InvestmentSection4";
import InvestmentSection1 from "./InvestmentSection1/InvestmentSection1";
import AssetDeveloperClub from "../Club/AssetDeveloperClub";
import IAATab from "../WebSite/IAATab/IAATab";
import InvestorTabs from "../WebSite/InvestorTabs/InvestorTabs";
import TitleDescription from "../TitleDescription/TitleDescription";
import InvestmentServices from "./InvestmentServices/InvestmentServices";
import InvestorChallenges from "./InvestorChallenges";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import InvestorContribution from "./InvestorContribution";
import CTA from "../CTA/CTA";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";

export default class ExploreInvestement extends React.Component {
  render() {
    return (
      <>
        <HeaderComponent />
        <UpdatesBanner  className="investmentContainer-wrapper"
      bannerTitle ="Start Investment Now" />

        <div className="main-wrapper2">
          <InvestorChallenges/>
        </div>

        <SolutionEcosystem type = "AFI" bodycopy=" Process for agri-investor to fulfill their needs."/>

        <div className="main-wrapper2 subSectionWrapper mb-0">
        <Container>
          <TitleDescription
          titleClass ="text-start"
          classname2 = "text-start"
            classname="mb-0 mt-0 "
            title="Who are  an Agri-Investors"
            description="Agri-investors, ranging from individual enthusiasts to financial institutions, are key stakeholders in agri-business and agri-entrepreneurship. By investing in these ventures and associated assets, they contribute to the economic growth of the agricultural sector while addressing global challenges related to food security and sustainability.
            "
          />

          {/* <AgriBusinessCard /> */}
        </Container>
      </div>
      <Container>
      <InvestmentServices/>
      </Container>
        <div className="main-wrapper2 subSectionWrapper">
        <h4 className="container-title text-center">
              Agri-Investor Benefits
            </h4>
            <InvestmentSection2 />
        </div>
        <div className="subSectionWrapper">
        <Container>
        <h4 className="container-title text-center">
              Agri-Investor Journey
            </h4>
            <InvestmentSection4 />
          </Container>
        </div>

          <div className="main-wrapper2">
        <Container>
          <InvestorContribution/>
       </Container>
          </div>











        <div className="subSectionWrapper pt-0">
        <Container>
              <CTA
                data="present"
                href2="http://abn.gbrfarming.com/auth/3/signup"
                heading="Invest Now: Grow Your Wealth with Strategic Asset Investments!"
                btnText="Register now for free"
              />
              </Container>
            </div>



{/* 
        <div className="investment-subcontainer">
          <Container className=" mt-5">
          <TitleDescription
        classname="mb-0 mx-md-4 mx-0"
        classname2 = "mb-0"
          title="Who are Agri-Investors"
          description="Agri-investors, ranging from individual enthusiasts to financial institutions, are key stakeholders in agri-business and agri-entrepreneurship. By investing in these ventures and associated assets, they contribute to the economic growth of the agricultural sector while addressing global challenges related to food security and sustainability.
          "
        />           
            <InvestmentSection1 />
            <AssetDeveloperClub />
           
          </Container>
          <InvestmentROIComponent />
          <Container className="mb-5">
         
          </Container>
        </div> */}
        <FooterComponent />
      </>
    );
  }
}
