import React, { useState } from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import ClubMainPage from '../Club/ClubMainPage';
export default function Agent() {

  const userTypes = ["AssetDeveloper"];
  return (
    <>
    <HeaderComponent/>
    <UpdatesBanner
          className="AgentBanner"
          bannerText={[  "Become an Agri-business agent to create  ",
          <br className="d-lg-block d-none"></br>,
          "Micro-entrepreneur in your region",]}
          bannerbodycopy="To apply for a job as an Agri-business Agent, please explore Agri-businesses "
          href="#AgentForm"
          buttonText5="Explore Agri-business"
          DlBtn ="true"
          dlLink="https://play.google.com/store/apps/details?id=com.gbrapp.agriminer&pli=1"
          DownloadText="Download App"
        />
        <div id="AgentForm">
        {/* <AgentForm usertype="Agent"/> */}
        <div className="subSectionWrapper">
        <ClubMainPage type={userTypes}  agribusiness="true" explore = "true"/>
      </div>

        </div>
    <FooterComponent/>
    </>
  )
}




