import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AdvisorsFeatures from "./AdvisorsFeatures";
import AdvisorsBenefits from "./AdvisorsBenefits";
import { Container } from "react-bootstrap";
import CTA from "../CTA/CTA";
import "./ForAdvisors.css";

export default function ForAdvisors() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="advisors-background"
        bannerText={[
          "Unlock your potential as an advisor and attract more customers today! ",
        ]}
        buttonText2="Sign in"
        buttonText3="Sign up"
        href2="https://abn.gbrapp.com/"
        href3="https://abn.gbrapp.com/signup"
      />
      <div className="main-wrapper2">
        <Container>
          {" "}
          <AdvisorsFeatures />
        </Container>
      </div>
      <Container>
        <AdvisorsBenefits />
      </Container>
      <Container>
        <div className=" subSectionWrapper">
          <CTA
            href="/auth/3/signup"
            heading="Join now to expand your client base and elevate your advisory services!"
            btnText="Register Now"
            onClick={() => {
              localStorage.setItem("userType", "Buyer");
            }}
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
