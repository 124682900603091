import React from "react";
import GovernmentOrganizationImg from "../../images/gbrHome/govenmentOrganization.webp";
import AdvisorImg from "../../images/gbrHome/advisor.webp";
import InvestorImg from "../../images/gbrHome/investor.webp";
import MachineryImg from "../../images/gbrHome/machinery.webp";
import ExporterImg from "../../images/gbrHome/exporter.webp";
import MSMEImg from "../../images/gbrHome/logisticproviders.webp";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
import { Container } from "@material-ui/core";


export default function OurPartners(props) {
  const exploreButton = props.exploreBtn;
  return (
    <div className="subSectionWrapper">
      <Container>
        <h4 className="container-title text-center mx-md-4 ">
          
          Partners
        </h4>

     
      </Container>
   

 
      <HomeSubSection
        id="ABN-Investor"
        className3="image2"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
                Agrifood Investors"
        description="Agrifood Investors are key partners for GBR, investing in both Core Agribusiness and Micro-entrepreneurs. Their financial support drives growth and sustainability in the agricultural sector. Focusing on key activities and empowering Micro-entrepreneurs, Agri Investors contribute to a diverse and impactful investment portfolio, enhancing GBR's success and benefiting the broader agricultural community.
        "
        src={InvestorImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/17/signin"
        // buttoncont1={`${exploreButton ? "Explore Agri Investors" : "Sign In"} `}
        exploreButton={exploreButton}
        href3="/ExploreInvestment"
        buttoncont3="Explore More"
       onClick={() => {
          localStorage.setItem("userType", "AssetOEM");
        }} 
        description1="Banks | NBFCs | Angel Investors | Institutional Investors "
      />



    
      <HomeSubSection
        id="ABN-Advertisement"
        className3="image3"
        className="rowReverseAbout--mod borderedCards"
        description="Agri-Input Manufacturers are crucial partners for GBR, supplying essential inputs like animal feed, supplements, and healthcare products. This collaboration ensures a reliable source of quality inputs, supporting GBR in maintaining optimal animal health and well-being. The partnership underscores the importance of a strong relationship with input manufacturers for the overall success of livestock farming.
                "
        descriptionTitle="
                Agri-Input Manufacturers"
        description1="FMC | PMC | AHSP | AIC"
        src={MachineryImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/20/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Machinery Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/input-manufacturers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "PMC");
        }}
      />

    

<HomeSubSection
        id="ABN-Govt"
        className3="forManufactures"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
         Brands"
        description="Food brands partnering with the ABN Network leverage the platform's global reach and expertise to enhance their visibility, distribution channels, and market presence. Through this collaboration, food brands gain access to a vast network of suppliers, retailers, and consumers, enabling them to expand their market reach and foster brand recognition on an international scale. By aligning with the ABN Network's values of quality, sustainability, and innovation, these food brands can leverage strategic partnerships and resources to drive growth, enhance product offerings, and create value for both their business and consumers. "
        // description1="Local Goverment Institutions | Central Goverment Institutions | Private Institutions "
        src={GovernmentOrganizationImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/8/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Government Organizations" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/ForBrands"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("abnUser", "FOOD-PROCESSOR");
        }}
      />


 
      <HomeSubSection
        id="ABN-Transporters"
        className3="image5"
        className="rowReverseAbout--mod borderedCards"
        description="GBR collaborates seamlessly with Logistic Providers through the GBR Logistics Feature. This innovative platform empowers logistics companies and their teams to efficiently manage their entire fleet, including booking details, trip scheduling, and load coordination. GBR Platform facilitates smooth interactions between logistics providers and their drivers, whether in-house or third-party, streamlining the transportation process and enhancing overall operational efficiency within the agricultural supply chain.
        "
        // description="GBR Platform provides logistics faeture. With GBR Platform, logistics companies and their staff can manage their entire fleet, booking details, trips scheduling, scheduling loads & orders to their own drivers or 3rd party drivers."
        descriptionTitle=" Logistic Providers"
        src={MSMEImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/13/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore  Logistic Providers" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/logistic-providers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "LogisticProvider");
        }}
        description1= "Global Logistic Providers | Hyperlocal Transporters | Cold Chain Logistics Providers "
      />


    
      <HomeSubSection
        id="ABN-Govt"
        className3="image6"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        Government Organizations"
        description="GBR provides open platform for collaboration and engagemnet with farmer associations, local governments and private organizations in the farming sector to promote awareness of the financial and required services across India. "
        description1="Local Goverments | Central Goverments | Other Organizations"
        src={GovernmentOrganizationImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/8/signin"
        // buttoncont1={`${
        //   exploreButton ? "Explore Government Organizations" : "Sign In"
        // } `}
        exploreButton={exploreButton}
        href3="/agency"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "GovtDept");
        }}
      />
   
   {/* <HomeSubSection
            className3="image55"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Producers"
            description="GBR provides open platform for collaboration and engagemnet with farmer associations, local governments and private organizations in the farming sector to promote awareness of the financial and required services across India. "
            description1="Local Goverments | Central Goverments "
            src={GovernmentOrganizationImg}
            href2="auth/3/signup"
            buttoncont2="Register"
            // href1="auth/3/signin"
            // buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}
            href3="/ComingSoon"
            buttoncont3="Explore More"
            onClick={() => {
              localStorage.setItem("userType", "GovtDept");
            }}
          /> */}
      





   


    
    










      
     



<HomeSubSection
        id="ABN-Advisory"
        className="rowReverseAbout--mod borderedCards"
        className3="image1"
        description="Advisors at GBR are essential partners, providing expertise in animal health, plant health, and machinery. Their insights ensure the well-being of livestock, optimize crop management, and maintain efficient machinery operations. Committed to excellence, these advisors play a pivotal role in GBR's holistic approach to agriculture, contributing to overall farm success.
                "
        descriptionTitle="
                Advisors"
        src={AdvisorImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1=  "auth/19/signin"
        // buttoncont1= "Sign In"
        exploreButton={exploreButton}
        href3="/advisors"
        buttoncont3="Explore More"
        description1="Consultants | Agronomist | Vet | Certification agencies "
        onClick={() => {
          localStorage.setItem("userType", "MSME-ADVISOR")
        }}
      />
            
{/* <HomeSubSection
            className3="technology-provider-image"
            className="rowReverseAbout--mod borderedCards pb-0"
            descriptionTitle="
        Technology Providers"
            description="GBR is proud to partner with leading technology providers to advance our agricultural practices. This collaboration integrates state-of-the-art technologies into our farming operations, enhancing efficiency, precision, and overall productivity. The expertise and innovative solutions provided by our technology partners contribute significantly to our success. Together, GBR and our technology partners are dedicated to leveraging the latest advancements to ensure sustainable and cutting-edge practices in modern agriculture.
            "
            description1="IoT Solution Providers | CRM & ERP Providers | Irrigation Providers | Satellite Imagery provider | Device Makers"
            href2="auth/3/signup"
            buttoncont2="Register"
            // href1="auth/3/signin"
            // buttoncont1={`${exploreButton ? "Explore Producers" : "Sign In"} `}
            exploreButton={exploreButton}
            href3="/ComingSoon"
            buttoncont3="Explore More"
          /> */}

<HomeSubSection
        id="ABN-Tenders"
        className3="image4"
        className1="aboutBgGrey"
        className="rowReverseAbout borderedCards"
        descriptionTitle="
        F&B Buyers"
        description="F&B Buyers are integral partners for GBR.Network, forming a crucial link in the agri-food supply chain. These partners play a vital role in the success of GBR.network by purchasing processed, semi-processed and raw products. The collaboration with buyers ensures a market for the  agri-products, contributing to financial sustainability and fostering a mutually beneficial relationship. This partnership highlights the interconnected nature of the agricultural ecosystem, where agri-producer, processor and buyers work together within GBR.Network guidelines to meet end users market demands and achieve overall success."
        // description="Through GBR distributor marketplace portal allows the corporate buyers to act as local distributor and exporters . Through this portal, corporate buyers keep track of transactions, make online orders and track the orders . The benefits for corporate buyers is to get quantity, quality and availability market fit assets for processing that have a direct impact on operations of downstream food supplier companies."
        // description1="Forecasting | Quality Certified| Reliable Supply | Fair Price | Improved Operational Efficency"
        src={ExporterImg}
        href2="auth/3/signup"
        buttoncont2="Register"
        // href1="auth/21/signin"
        // buttoncont1={`${exploreButton ? "Explore Exporters" : "Sign In"} `}
        // exploreButton={exploreButton}
        href3="/f&b-buyers"
        buttoncont3="Explore More"
        onClick={() => {
          localStorage.setItem("userType", "AssetDistributor");
        }}
        description1="Corporate Buyers | Merchant Exporters | Distributors | Wholesalers | Brands | Retailers "
      />
    </div>


  );
}
