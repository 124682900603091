import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import CTA from "../CTA/CTA";
import AgroDealerChallenges from "../AgroDealerChallenges/AgroDealerChallenges";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgroDealerServices from "./AgroDealerServices";
import AgroDealerPricing from "../pricingTabs/AgroDealerPricing";
import AgroDealerBenefits from "./AgroDealerBenefits";
import AgriRetailerBenefits from "./AgriRetailerBenefits";
export default function AgroDealers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="agriEntrepreneur-bg"
        bannerText={[
          "Serving Local farmers and Agri-Retailer ",
          <br className="d-lg-block d-none"></br>,
          "through Agro-Dealer Network ",
        ]}
       
      />
      <Container>
        <TitleDescription
          classname=""
          title="Who are an Agro-Dealers"
          description={[
            "Agro-dealers serve as vital intermediaries, connecting agri-retailers and farmers with crucial agricultural inputs and services at the local level. They offer a range of resources, including seeds, fertilizers, and pesticides, while also providing technical support, credit facilities, and market access. Through these services, agro-dealers contribute significantly to improving agricultural productivity, empowering farmers with the necessary tools and knowledge for sustainable and profitable farming practices.",
          ]}
        />
              </Container>

      <AgroDealerChallenges />

      <SolutionEcosystem
        type="ADealer"
        bodycopy="System and Process for Agro Delalers to create, manage and own ARN ( Agri-retailer Network ) to expand their reach"
      />
      <AgroDealerServices />
      {/* <AgroDealers/> */}
      <AgroDealerBenefits />
      <AgriRetailerBenefits />
      {/* <AgroDealerCustomerBenefit/> */}
      <h2 className="container-title text-center ">Agro-Dealer Pricing</h2>

      <AgroDealerPricing />
      <Container>
        <div className=" subSectionWrapper">
          <CTA
            href="/auth/3/signin"
            heading="Grow with us! Join now for exclusive agro-deals and boost your business. Seize the opportunity today!"
            btnText="Register Now"
            onClick={()=>{
              localStorage.setItem("userType", "AIC")
            }}
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
