import React from "react";
import img1 from "../../images/manufacturerBenefits/service (1).svg";
import img2 from "../../images/manufacturerBenefits/organic.svg";
import img3 from "../../images/manufacturerBenefits/search.svg";
import img4 from "../../images/manufacturerBenefits/no-preservatives.svg";
import img5 from "../../images/brands-icon/expert.svg"
import img6 from "../../images/brands-icon/reliability.svg"
import { Row, Col } from "react-bootstrap";
import BenefitsCard from "../BenefitsCard/BenefitsCard";

export default function NetworkOperatorBenefits() {
  const benefits = [
    {
      img: img1,
      title: "Improve Revenue",
      bodycopy: "ABN Network enhances revenue for network operators through improved network performance and reliability, leading to increased customer satisfaction and higher usage rates.",
    },
    {
      img: img2,
      title: "Expand Business",
      bodycopy: "Expanding into the agrifood sector benefits network operators by diversifying their clientele, increasing revenue streams, and fostering business growth.",
    },
    {
      img: img3,
      title: "New Agri-Investment class",
      bodycopy:
        "Enabling network operators to introduce an Agri-investment class for their users, provides a valuable benefit by expanding investment options, diversifying portfolios, and fostering financial inclusivity within the agricultural sector.",
    },
  
  ];
  return (
    <div className=" ">
      <h2 className="text-center container-title mb-4">
        Network Operator Benefits
      </h2>
      <Row>
        {benefits.map((e) => {
          return (
            <Col md={4} className="d-flex">
              <BenefitsCard src={e.img} title={e.title} bodycopy={e.bodycopy} />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
