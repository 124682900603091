import React from 'react'
import AboutList from './AboutList'

export default function CBNSAAS() {
    const data=[
  "Contract Farming Services : Contract Farming is a service provided by ABN Network to agribusiness for facilitating mutually beneficial agreements between farmers and buyers for the production and supply of agricultural products.  ",
"Contract Manufacturing Services:  Contract Manufacturing is a service provided by ABN Network to agribusiness for outsourcing the production of agricultural goods to specialized manufacturing facilities under mutually agreed contracts.",

       
    ]
  return (
   <AboutList title="Contract Service" contentlist={data}/>
  )
}