import React from 'react'
import AboutList from './AboutList'

export default function ProductionService() {
    const data=[
  "onMarketplace:  onMarketplace is a marketplace provided by ABN Network for buying and selling agricultural products to a wider consumer."  ,
"Social onMarketplace:   Social onMarketplace is a platform provided by ABN Network that integrates social features for agribusinesses and brands, facilitating interaction and commerce within the agricultural community.",
"AgroCare Marketplace:  Agrocare Marketplace, provided by ABN Network, offers a range of animal and plant care products, catering to the needs of agricultural businesses and enthusiasts.",
"Social iaaMarketplace: Social iaaMarketplace, provided by ABN Network, is a platform designed for agribusinesses and investors to interact, collaborate, and conduct transactions within the agricultural sector."
       
    ]
  return (
   <AboutList title="Marketplace" contentlist={data}/>
  )
}