import React from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./ServicesComponent.css";
import Button from "@material-ui/core/Button";
import "react-awesome-slider/dist/styles.css";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import coreServicesImg from "../../images/saas-icon.png";
import complementaryServicesImg from "../../images/complementary.png";
import playGroundServicesImg from "../../images/engagement-icon.png";
import MSMEServiceImg from "../../images/link.png";
import AdvisoryServiceImg from "../../images/operator.png";
import DigitalServiceImg from "../../images/digital-services.png";
import SupplementaryServiceImg from "../../images/professional-services.png";


import ServiceTypes from "../ServiceTypes/ServiceTypes";
import { NavLink } from "react-router-dom";

export default class ServicesComponent extends React.Component {
  serviceTypes = [
    {
      id: "coreServices",
      img: coreServicesImg,
      alt: "coreService",
      url: "/#ExploreABN",
      title: "Core Services",
      bodycopy: "GBR Provides ABN service for Agri Business as a core service.",
    },

    {
      id: "complementaryServices",
      img: complementaryServicesImg,
      alt: "complementaryServicesImg",
      url: "/#ExploreIAA",
      title: "Complementary Services",
      bodycopy: `GBR Provides "Investable Agri Asset" class for retail investor as complementary Service.`,
    },

    {
      id: "SupplementryServices",
      img: SupplementaryServiceImg,
      alt: "complementaryServicesImg",
      url: "/",
      title: "Supplementry Services",
      // bodycopy: `GBR provides ABN Network for FI and Large enterprise to Create, Manage and Operate their own network.`,
      disabledbtnClass: "test",

      supplementry1: "true",
      supplementry1Title: "Own ABN Network",
      supplementry1desp:
        "GBR provides ABN Network for FI and Large enterprise to Create, Manage and Operate their own Agribusiness network.",
      supplementry1href: "/",
    },

    {
      id: "ProducerEngagementServices",
      img: playGroundServicesImg,
      alt: "playGroundServicesImg",
      url: "/ForProducers",
      title: "Producers Engagement Services",
      bodycopy: `Producers Engagement services includes engagemnet services through engagemnet platform called Agri-Playground.`,
    },

    {
      id: "MSMEEngagement",
      img: MSMEServiceImg,
      alt: "playGroundServicesImg",
      url: "/ForSMEs",
      title: "MSME Engagement Services",
      bodycopy: `MSME Engagement services includes engagemnet services through engagemnet platform called ABN.`,
    },

    {
      id: "TechnologyIntegrationServices",
      img: DigitalServiceImg,
      alt: "playGroundServicesImg",
      url: "/ExploreTechnology",
      title: "Technology Integration Services",
      bodycopy: `Technology Integration Services provides various stacks for Enterprises.`,
    },

    {
      id: "AdvancedAdvisoryService",
      img: AdvisoryServiceImg,
      alt: "playGroundServicesImg",
      url: "",
      title: "Advanced Advisory Services",
      bodycopy: `MSME Engagement services includes engagemnet services through engagemnet platform called ABN.`,
      disabledbtnClass: "test",
    },
  ];

  render() {
    return (
      <Container className="fullWidthContainer">
        <HeaderComponent />

        <div maxWidth="lg" className="serviceContainer">
          <div maxWidth="sm" className="gridSection">
            {/* <Grid>
              <div className="closeDiv">
                <a href="/">
                  <span className="iconCnt closeCnt">
                    <i className="material-icons">close</i>
                  </span>
                </a>
              </div>
            </Grid> */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              direction="row"
            >
              <Grid
                id="coreServices_link"
                className="hoverEffect"
                item
                md={4}
                sm={6}
                xs={12}
              >
                <a href="#coreServices">
                  <img
                    src={coreServicesImg}
                    alt="coreServices "
                    className="banner-icon mb-3"
                  />
                  <h4>
                    Core <br className="d-none d-lg-block"></br>Services
                  </h4>
                </a>
              </Grid>
              {/* <Grid
                id="complementaryServices_link"
                className="hoverEffect"
                item
                md={3}
                sm={6}
                xs={12}
              >
                <a href="#complementaryServices">
                  <img
                    className="banner-icon mb-3"
                    src={complementaryServicesImg}
                    alt="complementaryServices"
                  />
                  <h4>Complementary Services</h4>
                </a>
              </Grid> */}

              <Grid
                id="playGroundServices_link"
                className="hoverEffect"
                item
                md={4}
                sm={6}
                xs={12}
              >
                <a href="#SupplementryServices">
                  <img
                    src={SupplementaryServiceImg}
                    alt="hospitals"
                    className="banner-icon mb-3"
                  />
                  <h4>Supplementary Services</h4>
                </a>
              </Grid>
              <Grid
                id="playGroundServices_link"
                className="hoverEffect"
                item
                md={4}
                sm={6}
                xs={12}
              >
                <a href="#EngagementServices">
                  <img
                    src={MSMEServiceImg}
                    alt="hospitals"
                    className="banner-icon mb-3"
                  />
                  <h4>Engagement Services</h4>
                </a>
              </Grid>

              {/* <Grid
                id="playGroundServices_link"
                className="hoverEffect"
                item
                md={3}
                sm={6}
                xs={12}
              >
                <a href="#ProducerEngagementServices">
                  <img
                    src={playGroundServicesImg}
                    alt="hospitals"
                    className="banner-icon mb-3"
                  />
                  <h4>Producers Enagement Services</h4>
                </a>
              </Grid>

              <Grid
                id="playGroundServices_link"
                className="hoverEffect"
                item
                md={3}
                sm={6}
                xs={12}
              >
                <a href="#MSMEEngagement">
                  <img
                    src={MSMEServiceImg}
                    alt="hospitals"
                    className="banner-icon mb-3"
                  />
                  <h4>MSME Enagement Services</h4>
                </a>
              </Grid>

              <Grid
                id="playGroundServices_link"
                className="hoverEffect"
                item
                md={3}
                sm={6}
                xs={12}
              >
                <a href="#TechnologyIntegrationServices">
                  <img
                    src={DigitalServiceImg}
                    alt="hospitals"
                    className="banner-icon mb-3"
                  />
                  <h4>Technology Integration Services</h4>
                </a>
              </Grid>

              <Grid
                id="playGroundServices_link"
                className="hoverEffect"
                item
                md={3}
                sm={6}
                xs={12}
              >
                <a href="#AdvancedAdvisoryService">
                  <img
                    src={AdvisoryServiceImg}
                    alt="hospitals"
                    className="banner-icon mb-3"
                  />
                  <h4>Advanced Advisory Services</h4>
                </a>
              </Grid> */}

              {/* <Grid
                id="agristackApi_link"
                className="hoverEffect"
                item
                md={6}
                sm={6}
                xs={12}
              >
                <a href="#agristackApi">
                  <img src={agristackApiImg} alt="smart cities" />
                </a>
                <h4>AgriStack API</h4>
              </Grid> */}
            </Grid>
          </div>
        </div>

        <Container className="section2Container">
          <h4 className="container-title">WHY SERVICES ?</h4>
          <Grid container spacing={0} justifyContent="center">
            <Grid item md={8} sm={8} xs={12}>
              <p className="container-para mb-0">
                To maximize profit of Agri-MSME and provide innovative way to
                them to raise investments
              </p>
            </Grid>
          </Grid>

          <Container
            maxWidth="lg"
            className="fullWidthContainer service-section"
          >
            {/* {this.serviceTypes.map((e) => {
              return (
                <ServiceTypes
                  href={e.url}
                  alt={e.alt}
                  id={e.id}
                  src={e.img}
                  title={e.title}
                  description1={e.bodycopy}
                  disabledbtnClass={e.disabledbtnClass}
                />
              );
            })} */}

            <ServiceTypes
              href="/#ExploreABN"
              alt="coreService"
              id="coreServices"
              src={coreServicesImg}
              title="Core Services"
              description1="GBR Provides ABN service for Agri Business as a core service."
            />

            {/* <ServiceTypes
              href="/#ExploreIAA"
              alt="coreService"
              id="complementaryServices"
              src={complementaryServicesImg}
              title="Complementary Services"
              description1="GBR Provides Investable Agri Asset class for retail investor as complementary Service."
            /> */}

            {/* <h3>Supplementary Service</h3> */}

            <ServiceTypes
              alt="coreService"
              id="SupplementryServices"
              src={SupplementaryServiceImg}
              title="Supplementry Services"
              // description1="GBR Provides Investable Agri Asset class for retail investor as complementary Service."
            />
            <div className="serviceSubSection mb-3">
              <h3>CBN Services</h3>
              <p>
                GBR provides ABN Network for FI and Large enterprise to Create,
                Manage and Operate their own Agribusiness network.
              </p>
              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod "
                type="button"
              >
                <a href="">REQUEST A/C</a>
              </Button>
            </div>

           

        
            <p className="section-inner-border">_________</p>
            <ServiceTypes
              alt="coreService"
              id="EngagementServices"
              src={MSMEServiceImg}
              title="Engagement Services "
              // description1="GBR Provides Investable Agri Asset class for retail investor as complementary Service."
            />

            <div className="serviceSubSection mb-3">
              <h3>Producers Engagement Services</h3>
              <p>
                Producers Engagement services includes engagemnet services
                through engagemnet platform called Agri-Playground.
              </p>
              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod "
                type="button"
              >
                <NavLink to="/ForProducers">REQUEST A/C</NavLink>
              </Button>
            </div>

            {/* <div className="serviceSubSection">
              <h3>MSME Engagement Services</h3>
              <p>
                MSME Engagement services includes engagemnet services through
                engagemnet platform called ABN.
              </p>
              <Button
                variant="contained"
                color="primary"
                className="registerBtn--mod "
                type="button"
              >
                <NavLink to="/ForSMEs">REQUEST A/C</NavLink>
              </Button>
            </div> */}
            <p className="section-inner-border">_________</p>
            {/*  <Grid
            container
            id="agristackApi"
            spacing={0}
            className="fullWidthContainer"
          >
            <Grid item md={2} sm={2} xs={0}></Grid>
            <Grid item md={8} sm={8} xs={12} className="contentContainer">
              <img
                src={agristackApiImg}
                alt="Project Ideation"
                className="section-icon"
              />
              <h4>AgriStack API</h4>
              <p>
                BoxPwr’s multi-cloud connectivity framework enables seamless
                &amp; secure data routing to any cloud, even to multiple clouds
                simultaneously. Being able to have IoT devices connect to cloud
                platforms is key for any IoT solution development.{" "}
              </p>
              <p>
                It is a secure and highly available framework that can route any
                messages to any cloud, and even to multiple clouds
                simultaneously. It provides well defined secure APIs to develop
                cloud adapters and can be deployed on private, public or hybrid
                cloud.
              </p>

              <Button
                variant="contained"
                color="primary"
                className="registerBtn"
                type="button"
              >
                <a href="/auth/10/farmersignup">REQUEST DEMO</a>
              </Button>
              <p className="section-inner-border">_________</p>
            </Grid>
            <Grid item md={2} sm={2} xs={0}></Grid>
          </Grid> */}
          </Container>
        </Container>
        <FooterComponent />
      </Container>
    );
  }
}
