import React from "react";
import FarmerImg from "../../../images/gbrPN/PNImg1.webp";
import FarmersFranchiseImg from "../../../images/gbrPN/PNImg5.webp";
import HomeSubSection from "../HomeSubSection/HomeSubSection";
import HeaderComponent from "../Header/HeaderComponent";
import NetworkBanner from "../NetworkBanner/NetworkBanner";

import FooterComponent from "../Footer/FooterComponent";
import AAMCNetworkSection1 from "./AAMCNetworkSection1";
import "./CMNNetwork.css";
export default function CMNNetwork() {
  return (
    <>
      <HeaderComponent />
      <NetworkBanner
        bannerTitle=" Contract Manufacturer Network for Digital Transformation in Agrifood Contract Manufacturing industry"
        bodycopy="Job creation | Digital transformation | Brand differentiation | Investable asset management | Organic certification"
        className="pnbg"
      />
      <div className="subSectionWrapper pb-0">
        <AAMCNetworkSection1
          descriptionTitle="Contract Manufacturer Network Cloud"
          description="
CMN is comprehensive cloud based solution specifically build for  honey processing and white labelling  purpose , offering end-to-end production traceability and centralized multi-factory management software.  This network provides provenance , reports, and proper insights into processes to improve quality.  This network managed and operated with in blockchain designated network to create trust"
        />
      </div>
      {/* <AAMCNetworkServices title="Agri Asset Management Cloud Services"/> */}
      {/* <MoneyMakingAssets /> */}

      {/* <IAABenefit />
      <IAAManagement /> */}

<div className="main-wrapper2">
            <div className="subSectionWrapper ">
        <h2 className="container-title text-center mt-4">
          CMN Network Operator{" "}
        </h2>
        <HomeSubSection
        id="ABN-Investor"
        className3="agri-foodmanufacturer-img"
        className="rowReverseAbout borderedCards "

        descriptionTitle="
        Food Processing Enterprises"
        description="
        Food processing enterprises benefit significantly from joining the ABN Network. It provides access to contract manufacturing opportunities with renowned brands, ensuring a steady stream of business and entry into lucrative markets. Additionally, GBR facilitates the establishment of digital sub-networks, optimizing operations and communication. Membership also grants access to a broader marketplace, enabling enterprises to showcase products to a wider audience and drive growth. In summary, joining GBR empowers food processing enterprises to expand reach, enhance efficiency, and thrive in the competitive food industry landscape.
        "
       
      />
</div>
</div>

      <div className="network-wrapper">
        <h2 className="container-title text-center mt-4">
        CMN Network Members
        </h2>
        {/* <h4 className="container-title text-center mx-4">
        Production Network (PN) - Investable Asset Management network for MSMEs
      </h4>
      <p className="container-para text-center mx-md-0 mx-4 mb-4 ">MSME can Build, Own, Manage & Operate Production Network</p> */}

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="pn-executive"
          description="Plant executives play a pivotal role in overseeing all facets of manufacturing operations. They are responsible for optimizing production processes, ensuring quality standards, managing budgets, and fostering a safe work environment. Through strategic planning, leadership, and continuous improvement initiatives, they drive efficiency, productivity, and profitability within the plant while upholding compliance with regulations and meeting customer demands."
          descriptionTitle="
          Plant Executive"
          src={FarmerImg}
     
          // href2="auth/3/signin"
          // buttoncont2="Sign in"
          // buttoncont7="Sign up"
          // onClick={() => {
          //   localStorage.setItem("userType", "Grower");
          // }}
          // href7="/auth/3/signup"
          // descriptionTitle2= "What our plant "
          description2="The GBR plant executive is reviewing project statuses provided by plant operators and quality assurance personnel across various plants to ensure efficient oversight and management of operations."
        />

        <HomeSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
        Plant Manager"
          description="
        Plant managers are individuals responsible for overseeing the day-to-day operations of manufacturing facilities. They supervise production activities, manage staff, and ensure the efficient and safe operation of the plant. This role involves tasks such as scheduling production runs, maintaining quality standards, and optimizing workflows. Plant managers also play a crucial role in coordinating departments within the plant, including production, maintenance, and quality control, to meet production goals. They may also be involved in strategic planning, budgeting, and implementing process improvements to enhance productivity and profitability. Overall, plant managers are instrumental in driving operational efficiency and achieving organizational objectives in manufacturing environments.      "
          src={FarmersFranchiseImg}
        
          className3="pn-manager"
          description1="As the GBR Plant Manager, responsibilities include executing projects assigned by brands, receiving project status updates from plant operators and QA teams, and sharing this information with the brands to ensure transparency and alignment.
          "
        />

        <HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="pn-operator"
          description="
          A plant operator is responsible for operating and maintaining machinery within manufacturing or industrial plants. Their duties include monitoring equipment performance, troubleshooting issues, and making necessary adjustments to ensure smooth operation. Plant operators are trained to adhere to safety protocols, conduct routine inspections, and perform preventive maintenance tasks. They play a crucial role in maintaining production efficiency and quality standards while minimizing downtime and ensuring compliance with regulations. Overall, plant operators are essential personnel in industrial settings, contributing to the smooth functioning of operations."
          descriptionTitle="
          Plant Operator"
          src={FarmerImg}

          description1="The GBR Plant operator communicates project status updates to both the Plant Manager and the Plant Executive, facilitating effective coordination and decision-making within the plant's management hierarchy."
        />



        <HomeSubSection
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          className3="pn-qa"
          description="Plant QA-QC personnel are responsible for ensuring that products manufactured meet quality standards. They monitor production processes, conduct inspections, and identify defects or deviations. They develop and implement quality control procedures and provide training to plant staff. Collaborating with other departments, they address quality issues and implement corrective actions. Overall, they play a crucial role in maintaining product quality, customer satisfaction, and regulatory compliance within manufacturing plants."
          descriptionTitle="
          Plant QA-QC"
          src={FarmerImg}
       
          description1="The GBR Plant QA team provides project quality status reports to both the Plant Manager and the Plant Executive, enabling them to make informed decisions and maintain high-quality standards throughout project execution."
        />
<HomeSubSection
          className="rowReverseAbout borderedCards borderedCards--mod"
          className3="pn-technician "
          description="
          A plant technician is a skilled professional responsible for operating, maintaining, and troubleshooting machinery and equipment within a manufacturing or industrial plant. They play a crucial role in ensuring the smooth functioning of the plant's operations, often performing tasks related to equipment installation, repair, and preventive maintenance."          descriptionTitle="
          Plant Technician "
          src={FarmerImg}
      
          // description1="The GBR Plant operator communicates project status updates to both the Plant Manager and the Plant Executive, facilitating effective coordination and decision-making within the plant's management hierarchy."
        />
        <HomeSubSection
          className1="aboutBgGrey"
          className3="pn-owner"
          className="rowReverseAbout--mod borderedCards borderedCards--mod"
          descriptionTitle="
        Agri-Contract Manufacturer"
          description="The plant owner refers to the individual, group, or entity that owns and holds responsibility for a manufacturing or industrial plant. Plant ownership can vary, ranging from private individuals or partnerships to corporations or government entities, depending on the industry and the nature of the plant. Plant owners typically invest in the establishment, operation, and maintenance of the facility, overseeing its strategic direction, financial management, and adherence to regulations. They may also be involved in decision-making regarding production processes, capital investments, and overall business operations. Overall, the plant owner holds legal and financial accountability for the plant's activities and performance.
      "
          src={FarmersFranchiseImg}
          // href2="auth/3/signup"
          // buttoncont2="Register"
          // href1="auth/3/signin"
          // buttoncont1="Sign In"

          // href7='http://cbn.gbrapp.com/'
      
        />
      </div>
      <FooterComponent />
    </>
  );
}
